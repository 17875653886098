import { FC, useMemo, useState } from "react";
import FacebookShareButton from "react-share/es/FacebookShareButton";
import { icons, images } from "../../assets";
import { CarouselSlider } from "../../components/CarouselSlider";
import { Snackbar } from "../../components/Snackbar";
import { AGES, SOCIALS } from "../../utils/data/constants";
import {
  copyAddress,
  displayNotify,
  isEmail,
} from "../../utils/helpers";

export const Landing: FC = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [age, setAge] = useState<number>(0);
  const [youtube, setYoutube] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [notification, setNotification] = useState<string>("");

  const ages = (age: number) => {
    switch (age) {
      case 0:
        return `url(${images.first_h})`;
      case 1:
        return `url(${images.second_h})`;
      case 2:
        return `url(${images.third_h})`;
      default:
        break;
    }
  };

  const ageImg = useMemo(() => {
    return ages(age);
  }, [age]);

  const styles = {
    backgroundImage: ageImg,
    backgroundSize: "cover",
  };

  function toggleAges(cmd: string = "inc") {
    if (cmd === "dec") {
      if (age - 1 >= 0) {
        setAge(age - 1);
      }
    } else {
      if (age + 1 < AGES.length) {
        setAge(age + 1);
      }
    }
  }

  const preRegister = (val: string) => {
    const _email = val;
    if (isEmail(_email)) {
      var formData: FormData = new FormData();
      formData.append("u_email", _email);

      fetch("https://api.aethergames.io/register/email", {
        method: "POST",
        body: formData,
      })
        .then((res) => {
          if (res.status === 200) {
            setNotification(`${_email} Registered Successfully`);
          } else if (res.status === 202) {
            setNotification(`WARN :: Email Already Registered!`);
          } else {
            setNotification(`ERROR :: Registeration Not Successful`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setNotification("ERROR :: Invalid Email Address");
    }
    displayNotify();
    setTimeout(() => {
      setEmail("");
      setNotification("");
    }, 3000);
  };

  return (
    <div className="landing">
      <Snackbar msg={notification} />
      <div
        className={`sec a image-${imageLoaded ? "visible" : "hidden"}`}
        onLoad={() => setImageLoaded(true)}
      >
        <img src={images.goe} />

        <div className="subscibe flex col center">
          <h2>Subscribe to our mailing list</h2>
          <div className="flex wrap center" style={{ width:'100%'}}>
            <input
              type={"email"}
              placeholder="E-mail adress"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={() => preRegister(email)}>Pre Register</button>
          </div>
        </div>
      </div>

      <div className="sec b">
        <div className="card">
          <ul className="flex center wrap">
            <li>
              <img src={icons.pc} />
              <p>Desktop</p>
            </li>
            <li>
              <img src={icons.unity} />
              <p>Unity</p>
            </li>
            <li>
              <img src={icons.hdrp} />
              <p>HDRP</p>
            </li>
          </ul>
        </div>

        <div className="b-desc flex col center">
          <img src={images.logo2} />
          <p>
            Gates of Ethernity (GoE) is a highly addictive strategic gameplay
            where players are challenged to make well-thought-out decisions,
            given the limited number of characters to choose from in each round.
            The goal is not just winning, but also includes the thrilling
            journey of collecting and crafting together the perfect team.
          </p>
          <ol className="flex center">
            {SOCIALS.map((s, idx) => (
              <li key={idx}>
                <a href={s.link} target={"_blank"} rel="noreferrer">
                  <img src={s.icon} alt="" />
                </a>
              </li>
            ))}
          </ol>
        </div>
      </div>

      <div className="sec c">
        <div className="rotate" />
        <section>
          <h2>The Story</h2>
          <ol>
            <i onClick={() => toggleAges("dec")}>
              <img src={icons.r_arr} />
            </i>
            <li>
              <div style={styles}>
                <span>
                  <h4>
                    The
                    <br />
                    {AGES[age]["title"]}
                  </h4>
                  <h5>Age</h5>
                </span>
              </div>
            </li>
            <i onClick={() => toggleAges()}>
              <img src={icons.r_arr} />
            </i>
          </ol>

          <ul>
            {AGES.map((a, idx) => (
              <li
                key={idx}
                onClick={() => window.open("story" + `?age=` + idx)}
              >
                <div>
                  <span className="flex col center">
                    <h4>
                      The <br />
                      {a.title}
                      <br />
                    </h4>
                    <h5>Age</h5>
                  </span>
                </div>
              </li>
            ))}
          </ul>
          <a href="/story">Discover the story</a>
        </section>
      </div>

      <div className="sec d">
        <h2>The Creatures</h2>
        <CarouselSlider/>
      </div>

      <div className="sec e">
        {youtube && (
          <div className="youtube" onClick={() => setYoutube(false)}>
            <iframe
              src="https://www.youtube.com/embed/zFC38LA-Q7U"
              title="Gates of Ethernity CGI Teaser"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        )}
        <div className="vid">
          {/* <img src={images.vid}/> */}
          <video
            src={"https://api.aethergames.io/get/videos/Teaser.mp4"}
            loop
            autoPlay
            muted
          />

          <div className="vid_cont">
            <div>
              <h2>Production</h2>
              <br />
              <br />
              <p>
                At Aether Games, our goal is to use all our resources to create
                a widely known franchise. With a continuously growing story
                behind, we aim to capture the attention of all people and not
                just within the crypto space.
              </p>
            </div>

            <button onClick={() => setYoutube(true)}>Watch Trailer</button>

            <img src={images.partns} alt="" />
          </div>

          <div className="btns">
            <button
              onClick={() => {
                setNotification("Copied to clipboard");
                copyAddress("https://youtu.be/zFC38LA-Q7U");
              }}
            >
              <img src={icons.copy} />
            </button>
            <FacebookShareButton
              url={"https://youtu.be/zFC38LA-Q7U"}
              title="Discover the awesome GOE story"
            >
              <button>
                <img src={icons.share} />
              </button>
            </FacebookShareButton>
          </div>
        </div>
      </div>

      <div className="sec f">
        <div className="coe">
          <section>
            <a href="https://coe.gg/" target={"_blank"}>
              Visit website <img src={icons.right_arr} />
            </a>
            <img src={images.coe_logo} />
            {/* <h3>
              Cards of Ethernity (CoE) is a digital collectible competitive card
              play-and-earngame. At its core CoE is a blockchain-based game,
              enabling users to trade and sell theircards freely, with the same
              level of ownership as if they were real, tangible cards{" "}
            </h3> */}

            {/* <ul>
              <li>
                <img src={icons.block} />
                <h4>Blockchain-based game</h4>
              </li>
              <li>
                <img src={icons.cards} />
                <h4>150 collectible cards</h4>
              </li>
              <li>
                <img src={icons.token} />
                <h4>$AEG Token - in-game currency.</h4>
              </li>
              <li>
                <img src={icons.nft} />
                <h4>NFT drop packs</h4>
              </li>
            </ul> */}
          </section>
        </div>
        <div className="ag">
          <section>
            <a href="https://www.aethergames.io/" target={"_blank"}>
              Visit website <img src={icons.right_arr} />
            </a>
            <img src={images.ag_logo} />
            {/* <h3>
              Ethernity Games Inc. is a game development studio which creates
              high-end games within the crypto and NFT realm. All games
              published by Ethernity Games are coherent and built in the same
              franchise and metaverse. We are a joint venture of 3 of the most
              renowned studios in the gaming industry who have joined forces to
              offer the highest quality treatment to blockchain-based game
              development.
            </h3> */}
          </section>
        </div>
      </div>
    </div>
  );
};
