import { useEffect, useState } from "react";
import { icons, images } from "../../assets";
import { ARTICLE } from "../../types";
import { ARTICLES, SOCIALS } from "../../utils/data/constants";

export const Article = () => {
 

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const _article = urlParams.get("article");

  const [artic, setArtic] = useState<ARTICLE>({
    img:"",
    title:"",
    desc:[''],
    childs:[{
        sub_title:'',
        sub_desc:['']
    }]
  });

  const [more, setMore] = useState(0);

  useEffect(() => {
    if (_article) {
        setArtic((ARTICLES as any)[_article])
    }
  }, [_article]);



  return (
    <div className="article">
      <div className="art-bg">
        <div className="img-sec flex">
          <img src={artic.img} />
        </div>

        <section>
          <div className="bl_ls">
            <h2>{artic.title}</h2>
            {artic.desc.map((d, idx) => <p key={idx}>{d}</p>)}
            {artic.childs.map((c, idx) => 
                <>
                    <h4>{c.sub_title}</h4>
                    {c.sub_desc.map((s,idx) =>  
                        <p key={idx}>
                            {s}
                        </p>
                )}
                </>
            )}           
          </div>

          <div className="bl-rs">
            <div className="rs-soc">
              <h3>Follow Us</h3>
              <ul>
                {SOCIALS.map((s, idx) => (
                  <li key={idx}>
                    <a href={s.link}>
                      <img src={s.icon} />
                      <span>{s.title}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="rs-ba">
              <h3>Best Articles</h3>
              <ul>
                {Object.values(ARTICLES).map((b, idx) => (
                  <li key={idx}>
                    <a href={`/blog/article?article=${idx}`}>
                      <div>
                        <h6>
                          <img src={icons.cal} /> 2022 04
                          14&nbsp;&nbsp;&nbsp;&nbsp;|
                          <img src={icons.pers} /> 4 min read
                        </h6>
                      </div>
                      <section>
                        <img src={b.img} />
                        <p>{b.title}</p>
                      </section>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        <section className="more-art">
          <div className="art-nav flex wrap">
            <button onClick={() => setMore(0)}>
              <img src={icons.r_arr} />
              Previous
            </button>
            <button onClick={() => setMore(3)}>
              View More
              <img src={icons.r_arr} />
            </button>
          </div>

          <ul>
            {Object.values(ARTICLES).slice(more,more+3).map((b, idx) => (
              <li key={idx}>
                <a href={`/blog/article?article=${idx}`} className="flex">
                  <img src={b.img} />
                  <div className="flex col">
                    <h6>
                      <img src={icons.cal} /> 2022 04
                      14&nbsp;&nbsp;&nbsp;&nbsp;|
                      <img src={icons.pers} /> 4 min read
                    </h6>
                    <h2>{b.title}</h2>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </section>
      </div>
    </div>
  );
};
