import { FC } from 'react';
import { STORY_AGES } from '../../utils/data/constants';
import { lineStyle } from '../../utils/helpers';
import './styles.css';

export const TimeLine:FC<any> = (props:any) => {


    function stepClick(idx:number){
        handleAns(props.years[props.age][idx],idx)
        lineStyle(idx, props.age);
    };
    function handleAns(bool:boolean,idx:any){
        const newItemsArr = props.years.map((obj:any, a:number) =>
        obj.map((bo:boolean, index:number) =>  
            bo === bool && a === props.age && idx === index ? true : false
        ));
        const _temp = props.yearTitle.map((t:any,index:number) => index === props.age ? Object.keys(STORY_AGES[props.age]['years'])[idx] : props.yearTitle[props.age]);

        props.setYears(newItemsArr);
        props.setYearTitle(_temp);

    };
    

    return(
        <div id='progress-bar-container' key={props.age}>
            <ul>
                {props.years[props.age].map((_:any,idx:number) =>
                        <li key={idx} className={props.years[props.age][idx] ? 'step active' : 'step'} id={`step${idx}`} onClick={() => stepClick(idx)}>
                            <div className='diamond'/>
                        </li>
                    )}
            </ul>
            <div className="line">
                <div id={`line-progress${props.age}`}/>
            </div>
        </div>
    )
}