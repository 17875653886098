// WALLETS
import metamask from './icons/metamask.png';
import walletconnect from './icons/walletconnect.png';

import logo from './images/misc/logo.png';
import logo2 from './images/misc/logo2.png';
import ag_logo from './images/misc/ag_logo.png';
import coe_logo from './images/misc/coe_logo.png';
import ftr_logo from './images/misc/ftr_logo.svg';

import partns from './images/misc/partns.png';
import goe from './images/misc/goe.png';

import first from './images/misc/first.png';
import second from './images/misc/second.png';
import third from './images/misc/third.png';

import first_h from './images/misc/first_h.png';
import second_h from './images/misc/second_h.png';
import third_h from './images/misc/third_h.png';

import vid from './images/misc/vid.png';

// CREATURES
import Allana from './images/creatures/Allana.webp';
import Atatro from './images/creatures/Atatro.webp';
import Cera from './images/creatures/Cera.webp';
import Chaos_Obsidian_Maw from './images/creatures/Chaos_Obsidian_Maw.webp';
import Chaos_Stalker from './images/creatures/Chaos_Stalker.webp';
import Corvun from './images/creatures/Corvun.webp';
import Cryo_Fiend from './images/creatures/Cryo_Fiend.webp';
import Dokhogar from './images/creatures/Dokhogar.webp';
import Frida from './images/creatures/Frida.webp';
import Gag_Draz from './images/creatures/Gag_Draz.webp';
import Gog_Tomak from './images/creatures/Gog_Tomak.webp';
import Gromgold from './images/creatures/Gromgold.webp';
import Kuroji from './images/creatures/Kuroji.webp';
import Licin from './images/creatures/Licin.webp';
import Obsidian_Maw from './images/creatures/Obsidian_Maw.webp';
import Onira from './images/creatures/Onira.webp';
import Oz_Gadik from './images/creatures/Oz_Gadik.webp';
import Shiny_Chaos_Stalker from './images/creatures/Shiny_Chaos_Stalker.webp';
import Stonewing from './images/creatures/Stonewing.webp';
import Tidal_Wyrm from './images/creatures/Tidal_Wyrm.webp';
import Viridis_Watcher from './images/creatures/Viridis_Watcher.webp';
// ---CREATURES VIDEOS

import Allana_vid from './videos/creatures/Alana.mp4';
import Atatro_vid from './videos/creatures/Atatro.mp4';
import Cera_vid from './videos/creatures/Cera.mp4';
import Chaos_Obsidian_Maw_vid from './videos/creatures/Chaos_Obsidian_Maw.mp4';
import Chaos_Stalker_vid from './videos/creatures/Chaos_Stalker.mp4';
import Corvun_vid from './videos/creatures/Corvun.mp4';
import Cryo_Fiend_vid from './videos/creatures/Cryo_Fiend.mp4';
import Dokhogar_vid from './videos/creatures/Dokhogar.mp4';
import Frida_vid from './videos/creatures/Frida.mp4';
import Gag_Draz_vid from './videos/creatures/Gag_Draz.mp4';
import Gog_Tomak_vid from './videos/creatures/Gog_Tomak.mp4';
import Gromgold_vid from './videos/creatures/Gromgold.mp4';
import Kuroji_vid from './videos/creatures/Kuroji.mp4';
import Licin_vid from './videos/creatures/Licin.mp4';
import Obsidian_Maw_vid from './videos/creatures/Obsidian_Maw.mp4';
import Onira_vid from './videos/creatures/Onira.mp4';
import Oz_Gadik_vid from './videos/creatures/Oz_Gadik.mp4';
import Shiny_Chaos_Stalker_vid from './videos/creatures/Shiny_Chaos_Stalker.mp4';
import Stonewing_vid from './videos/creatures/Stonewing.mp4';
import Tidal_Wyrm_vid from './videos/creatures/Tidal_Wyrm.mp4';
import Viridis_Watcher_vid from './videos/creatures/Viridis_Watcher.mp4';



// icons
import close from './icons/close.svg';
import connect from './icons/connect.svg';
import pc from './icons/pc.svg';
import unity from './icons/unity.svg';
import hdrp from './icons/hdrp.svg';

import youtube from './icons/youtube.svg';
import telegram from './icons/telegram.svg';
import discord from './icons/discord.svg';
import twitter from './icons/twitter.svg';
import instagram from './icons/instagram.svg';

import yout from './icons/yout.svg';
import tele from './icons/tele.svg';
import dis from './icons/dis.svg';
import twi from './icons/twi.svg';
import insta from './icons/insta.svg';

import right_arr from './icons/right_arr.svg';

import block from './icons/block.svg';
import cards from './icons/cards.svg';
import token from './icons/token.svg';
import nft from './icons/nft.svg';
import r_arr from './icons/r_arr.svg';
import copy from './icons/copy.svg';
import share from './icons/share.svg';
import cal from './icons/cal.svg';
import pers from './icons/pers.svg';
import d_arr from './icons/d_arr.svg';
import menu from './icons/menu.svg';


// -----BLOGS
import generous from './images/blogs/generous.svg';
import ge from './images/blogs/ge.svg';
import cgc from './images/blogs/cgc.svg';
import ac from './images/blogs/ac.svg';
import art from './images/blogs/art-img.svg';


import a1 from './images/blogs/a1.png';
import a2 from './images/blogs/a2.png';
import a3 from './images/blogs/a3.png';
import a4 from './images/blogs/a4.png';
import a5 from './images/blogs/a5.png';
import a6 from './images/blogs/a6.png';


// -----------------------

import dragon from './images/misc/dragon.png';
import fairy from './images/misc/fairy.png';
import troll from './images/misc/troll.png';


// ------------------------------------------------
export const images = {
    logo, goe, logo2, ag_logo, coe_logo,ftr_logo, 
    first, second, third,
    first_h, second_h, third_h, 
    partns, vid,
    generous, ge, cgc, ac, art, dragon, fairy, troll,
    a1,a2,a3,a4,a5,a6
};

export const creatures = {
    backgrounds:{
        Allana, Atatro, Cera, Chaos_Obsidian_Maw, Chaos_Stalker, Corvun, Cryo_Fiend,
        Dokhogar, Frida, Gag_Draz, Gog_Tomak, Gromgold, Kuroji, Licin, Obsidian_Maw,
        Onira, Oz_Gadik, Shiny_Chaos_Stalker, Stonewing, Tidal_Wyrm, Viridis_Watcher
    },
    videos:{
        Allana_vid, Atatro_vid, Cera_vid, Chaos_Obsidian_Maw_vid, Chaos_Stalker_vid, Corvun_vid, Cryo_Fiend_vid,
        Dokhogar_vid, Frida_vid, Gag_Draz_vid, Gog_Tomak_vid, Gromgold_vid, Kuroji_vid, Licin_vid, Obsidian_Maw_vid, 
        Onira_vid, Oz_Gadik_vid, Shiny_Chaos_Stalker_vid, Stonewing_vid, Tidal_Wyrm_vid, Viridis_Watcher_vid
    }
}

export const icons = {
    metamask, walletconnect, close, 
    connect, pc, unity, hdrp ,
    youtube, telegram, discord,twitter, instagram,
    yout, tele, dis, twi, insta,right_arr,
    block, cards, token, nft, r_arr, copy, share,
    cal, pers, d_arr, menu
};