import { useCallback, useEffect, useRef, useState } from "react"
import { icons, images } from "../../assets"
import { TimeLine } from "../../components/TimeLine"
import { STORY_AGES } from "../../utils/data/constants"
import { countWords, lineStyle } from "../../utils/helpers"

export const Story = () => {

    const agesRef = useRef([]) as any;
    const [textsNum, setTextsNum] = useState<number>(0);
    const [yearTitle, setYearTitle] = useState(['YEAR 0','YEAR 0','YEAR 0 / YEAR 967 CE'])

    const [years, setYears] = useState([
        [true, false, false, false, false, false],
        [true, false, false, false, false, false],
        [true, false, false, false, false, false]
    ]);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const _age = urlParams.get('age');

        if(_age){
            agesRef['current'][_age].scrollIntoView()
        }
    },[agesRef])


    const TextHandler = useCallback((desc:string) => {
        var _st:any = [];
        if(typeof(desc) !== "undefined"){
        var _c = Math.ceil(countWords(desc) / 100);
        console.log(_c)
            for(var i = 0; i< _c;i++){
                _st.push(desc.split(/\s+/).slice(i*100,(i+1)*100).join(" "));
            }
        }
        return _st
    },[yearTitle]);

    function handlePages(str:string){

        var len = Math.ceil(countWords(str) / 100);
        if(textsNum === len -1 ){
            setTextsNum(0)
        }
        if(textsNum + 1 < len ){
            setTextsNum(textsNum + 1)
        }
    };

    function HandleYear(bool:boolean,idx:any, age:number, title:string){
        const newItemsArr = years.map((obj, ind) =>
        obj.map((y, id) => 
            y === bool && idx === id && age===ind ? true : false
        )
        );
        const _temp = yearTitle.map((t,index) => index === age ? title : yearTitle[age]);
        setYears(newItemsArr);
        setYearTitle(_temp);
        lineStyle(idx, age);
        setTextsNum(0)
    };


    return(
        <div className="story">
            <div className="discover">
                <div className="rotate"/>
                <div>
                    <img src={images.ftr_logo}/>
                    <h1>The Story</h1>
                    <a>Discover <img src={icons.d_arr}/></a>
                </div>
            </div>

            {STORY_AGES.map((s, age) => 
                <div className={`age a${age}`} key={age} ref={el => agesRef.current[age] = el}>
                    <div className="f-cov">
                        <div className="age-img" >
                            <div className="particles"/>
                            <img src={s.img}/>
                        </div>
                        <section id="desk-story">
                            <ul>
                                {Object.keys(s.years).map((y,idx) => 
                                    <li key={idx} className={years[age][idx] ? `year y${age}` : 'year'} onClick={() => HandleYear(years[age][idx],idx, age, y)}><img/>{y}</li>
                                    )}
                            </ul>

                            <div className="paper">
                                <div className="paper-cont flex col center">
                                    <h1>the<br/>{s.title} <br/><span>age</span></h1>
                                    <div className="flex center">
                                        <h4>{yearTitle[age]}</h4>
                                    {TextHandler((s.years as any)[(yearTitle as any)[age]]).length > 1 && 
                                        <button onClick={() => handlePages((s.years as any)[(yearTitle as any)[age]])}>Page {textsNum + 1} from {Math.ceil(countWords((s.years as any)[(yearTitle as any)[age]]) / 100)}<img src={icons.r_arr}/></button>
                                    }
                                    </div>
                                    {/* DO SOME ANIMATION FOR THIS WHEN YEAR CHANGE */}
                                    <p>{TextHandler((s.years as any)[(yearTitle as any)[age]])[textsNum]}</p>
                                    
                                </div>
                                <div className="timeline">
                                        <TimeLine age={age} years={years} setYears={setYears} setYearTitle={setYearTitle} yearTitle={yearTitle}/>
                                    </div>
                            </div>
                            <ol/>
                        </section>

                        <section className="mini-story">
                            <div className="paper">
                                <div className="paper-cont flex col center">
                                    <h1>the<br/>{s.title} <br/><span>age</span></h1>
                                </div>
                                <div className="timeline">
                                    <TimeLine age={age} years={years} setYears={setYears} setYearTitle={setYearTitle} yearTitle={yearTitle}/>
                                </div>
                            </div>

                            <ul>
                                {Object.keys(s.years).map((y,idx) =>
                                    <div>
                                        <li key={idx} className={years[age][idx] ? `year y${age}` : 'year'} onClick={() => HandleYear(years[age][idx],idx, age, y)}>
                                            {y}
                                        </li>
                                        {years[age][idx] && (<p>{(s.years as any)[y]}</p>
                                    )}
                                    </div>
                                    )}
                            </ul>
                        </section>
                    </div>
                </div>
            )}
        </div>
    );
};