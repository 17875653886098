import { useState } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { icons, images } from "../../assets";
import { WalletModal } from "../../components/WalletModal";
import { TrimAddress } from "../../utils/helpers";
import { disconnect } from "../../utils/functions";

export const Navbar = () => {

  const { active, account, activate, deactivate} = useWeb3React<Web3Provider>();
  const [displayModal, setDisplayModal] = useState<boolean>(false);

  return (
    <>
    {displayModal&&(
            <WalletModal
                active={active}
                activate={activate}
                setDisplayModal={setDisplayModal}
            />
        )}
    <nav className="nav">
      <div className="nav-cont">

      <div className="logo">
          <a href="/"><img src={images.logo}/></a>
      </div>

      <div className="nav-buttons">
       <a href="https://mint.goe.gg/" target={"_blank"}>Mint</a>

        {
            account ? 
            <button onClick={() => disconnect(deactivate)} title="Disconnect Wallet">{TrimAddress(account)}</button>
            :
            <button onClick={() => setDisplayModal(true)}><img src={icons.connect}/>Connect Wallet</button>
        }
        <button className="menu" onClick={() => window.scrollTo(0, document.body.scrollHeight)}><img src={icons.menu}/></button>
      </div>
      </div>
    </nav>
    </>
  );
};
