import { creatures, icons, images } from "../../assets";

export const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const WALLET_PROVIDERS = [
  {
    title: "Metamask",
    logo: icons.metamask,
    desc: "Connect to your MetaMask Wallet",
  },

  {
    title: "WalletConnect",
    logo: icons.walletconnect,
    desc: "Scan with WalletConnect to connect",
  },
];

export const FOOTER = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "The Story",
    link: "/story",
  },
  {
    title: "Blog",
    link: "/blog",
  },
  {
    title: "Whitepaper",
    link: "https://coe.gg/pitchdeck.pdf",
  },
  {
    title: "Mint",
    link: "https://mint.goe.gg/",
  },
];

export const AGES = [
  {
    title: "FIRST",
    img: images.first_h,
  },
  {
    title: "SECOND",
    img: images.second_h,
  },
  {
    title: "THIRD",
    img: images.third_h,
  },
];

export const SOCIALS = [
  {
    title: "Youtube",
    img: icons.youtube,
    icon: icons.yout,
    link: "https://www.youtube.com/channel/UCaYVucgHw9ZmYwGoxH1izgQ",
  },
  {
    title: "Telegram",
    img: icons.telegram,
    icon: icons.tele,
    link: "https://t.me/AetherGamesAnnouncements",
  },
  {
    title: "Discord",
    img: icons.discord,
    icon: icons.dis,
    link: "https://discord.com/invite/AetherGames",
  },
  {
    title: "Twitter",
    img: icons.twitter,
    icon: icons.twi,
    link: "https://twitter.com/AetherGamesInc",
  },
  {
    title: "Instagram",
    img: icons.instagram,
    icon: icons.insta,
    link: "https://www.instagram.com/aethergamesinc/",
  },
];

export const CREATURES = [
  {
    name: "Donir",
    category: "DRAGON FIRE",
    evo: "",
    bg: creatures.backgrounds.Obsidian_Maw,
    vid: creatures.videos.Obsidian_Maw_vid,
  },
  {
    name: "Jeg'dreth",
    category: "DEMON ICE",
    evo: "",
    bg: creatures.backgrounds.Cryo_Fiend,
    vid: creatures.videos.Cryo_Fiend_vid,
  },
  {
    name: "ALLANA",
    category: "FAIRY LIGHT",
    evo: "",
    bg: creatures.backgrounds.Allana,
    vid: creatures.videos.Allana_vid,
  },
  {
    name: "Konynth",
    category: "DRAGON EARTH\nSTARTER",
    evo: "",
    bg: creatures.backgrounds.Stonewing,
    vid: creatures.videos.Stonewing_vid,
  },
  {
    name: "ONIRA",
    category: "BEAST Electricity",
    evo: "",
    bg: creatures.backgrounds.Onira,
    vid: creatures.videos.Onira_vid,
  },
  {
    name: "DOKHOGAR",
    category: "TROLL air",
    evo: "",
    bg: creatures.backgrounds.Dokhogar,
    vid: creatures.videos.Dokhogar_vid,
  },

  {
    name: "ATATRO",
    category: "Beast Nature",
    evo: "",
    bg: creatures.backgrounds.Atatro,
    vid: creatures.videos.Atatro_vid,
  },
  {
    name: "Cera",
    category: "Elemental Electricity",
    evo: "",
    bg: creatures.backgrounds.Cera,
    vid: creatures.videos.Cera_vid,
  },
  {
    name: "Tergy",
    category: "Dragon chaos\nstarter",
    evo: "",
    bg: creatures.backgrounds.Chaos_Stalker,
    vid: creatures.videos.Chaos_Stalker_vid,
  },
  {
    name: "Corvun",
    category: "Dragon Chaos",
    evo: "",
    bg: creatures.backgrounds.Corvun,
    vid: creatures.videos.Corvun_vid,
  },
  {
    name: "Chaos Donir",
    category: "Dragon chaos",
    evo: "",
    bg: creatures.backgrounds.Chaos_Obsidian_Maw,
    vid: creatures.videos.Chaos_Obsidian_Maw_vid,
  },
  {
    name: "Gaddres",
    category: "Dragon Nature starter",
    evo: "",
    bg: creatures.backgrounds.Viridis_Watcher,
    vid: creatures.videos.Viridis_Watcher_vid,
  },
  {
    name: "Frida",
    category: "Fairy Ice",
    evo: "",
    bg: creatures.backgrounds.Frida,
    vid: creatures.videos.Frida_vid,
  },
  {
    name: "Gag'draz",
    category: "Demon Chaos",
    evo: "",
    bg: creatures.backgrounds.Gag_Draz,
    vid: creatures.videos.Gag_Draz_vid,
  },
  {
    name: "Gog'Tomak",
    category: "Demon water",
    evo: "",
    bg: creatures.backgrounds.Gog_Tomak,
    vid: creatures.videos.Gog_Tomak_vid,
  },
  {
    name: "Gromgold",
    category: "Troll Earth",
    evo: "",
    bg: creatures.backgrounds.Gromgold,
    vid: creatures.videos.Gromgold_vid,
  },
  {
    name: "Kuroji",
    category: "Troll Ice",
    evo: "",
    bg: creatures.backgrounds.Kuroji,
    vid: creatures.videos.Kuroji_vid,
  },
  {
    name: "Licin",
    category: "Beast chaos",
    evo: "",
    bg: creatures.backgrounds.Licin,
    vid: creatures.videos.Licin_vid,
  },
  {
    name: "Oz'gadik",
    category: "Demon ice",
    evo: "",
    bg: creatures.backgrounds.Oz_Gadik,
    vid: creatures.videos.Oz_Gadik_vid,
  },
  {
    name: "Golden Tergy",
    category: "Dragon chaos\nstarter",
    evo: "",
    bg: creatures.backgrounds.Shiny_Chaos_Stalker,
    vid: creatures.videos.Shiny_Chaos_Stalker_vid,
  },
  {
    name: "Posera",
    category: "Dragon water\nstarter",
    evo: "",
    bg: creatures.backgrounds.Tidal_Wyrm,
    vid: creatures.videos.Tidal_Wyrm_vid,
  },
];

// export const BLOGS = [
//     {
//         img:images.generous,
//         title:"Win and earn — the generous way",
//         desc:"In an earlier blog post, we covered the main difference between play-and-earn and play-to-earn when it comes to crypto gaming...."
//     },
//     {
//         img:images.ge,
//         title:"Genesis NFTs: Long Term Value & Secondary Market",
//         desc:"In an earlier blog post, we covered the main difference between play-and-earn and play-to-earn when it comes to crypto gaming...."
//     },
//     {
//         img:images.cgc,
//         title:"Genesis NFTs: Long Term Value & Secondary Market",
//         desc:"In an earlier blog post, we covered the main difference between play-and-earn and play-to-earn when it comes to crypto gaming...."
//     },
//     {
//         img:images.ac,
//         title:"Discord Art Competition Vol #1",
//         desc:"In an earlier blog post, we covered the main difference between play-and-earn and play-to-earn when it comes to crypto gaming...."
//     },
// ];

export const STORY_AGES = [
  {
    title: "First",
    img: images.dragon,
    years: {
      "YEAR 0":
        "The three Gods, immaterial and eternal, swim together through the Void. They move as one being, immutable and complete. Much time passes like this, and eventually they begin to experiment. They pull together the pieces of themselves, matter, space, and time, with meticulous abandon. They form stars, comets, galaxies and so the universe is shaped bit by bit. Finally, a planet, their favorite, emerges through the settling dust of creation. This planet intrigues them, and they decide, as eternal and whole as they are, to test their work. They cannot, however, experience it in their condition. After much contemplation they choose three separate forms: the first is Melkree, the ruler of matter, who takes the shape of a Troll to forge a deep connection with the earth. The second is Meoldrorth, ruler of space, who manifests as a Dragon and claims the freedom to travel long distances with ease. The third and final form is Vargad, the insistent and discreet ruler of time, presents as a Demon, and presides over mystery and all that lurks within the shadows.",
      "YEAR 200":
        "The three Gods spend many years adjusting to their new, separate bodies and experiencing their new, stimulating world. Two centuries pass and though they grow fond of the planet, loneliness weighs heavily upon each of them. They decide, after much thought, to create once again, this time modeling beings off their newfound forms. This, in short, is how Trolls, Dragons, and Demons came to be and for many years they live in peace, sharing the knowledge and resources of their world.",
      "YEAR 1347":
        "The prosperity once shared between the families has greatly deteriorated. The world has been divided into three vast territories over which many wars are raged. The Gods, greatly disappointed in the fickleness of their creations, retire their interest and forge a realm in which they may once again live together, leaving their children to fight over their planet.",
      "YEAR 1417":
        "Gredyrth, ruler of the Dragons and mad with greed, attacks the celestial fortress of the Gods. Her hunger for their power of creation drives her to their doors where, outnumbered and unprepared, they create Nayid, a powerful combination of the Gods’ desperation in the moment. The ferocious creature repels Gredyrth’s rebel Dragon armies with fearful ease. As thanks, the Gods create more creatures like Nayid, gifting them land that once belonged to the Dragons as penance for their avarice. This is how the family of Beasts came to be.\nIn the years that follow, Meoldrorth, expressing great disappointment in their children, puts an end to Gredyrth’s dictatorial reign and establishes the Seven Kingdoms of Tergaroth in the Dragon’s territory. This becomes a well-known place of intellect and diplomacy, where decisions are made collectively.",
      "YEAR 2111":
        "The families continue to feud, and through this an alliance between Beasts and Trolls is formed. Together, they attack the Dragons with merciless fervor and after many battles, the Dragons’ strength begins to weaken. Many times, in desperation they call upon the Demons for aide, only to be greeted with silence.",
      "YEAR 2119":
        "The wars rage on and the Pillars of Tergaroth fall to the Beast and Troll armies. As a last resort, the Seven Dragon Matriarchs call to their deity and implore them for help. Meoldrorth in turn asks the others, Melkree and Vargad, to join forces and bring peace to the land and their people. But the two Gods are jaded and have little desire to interfere in the affairs of the world, refusing the call to arms from the comfort of their celestial palace. Meoldrorth, in a fit of rage, pours out all their destructive magic onto the armies of Trolls and Beasts that decimating their people.\nMelkree, closest to Meoldrorth, manages to subdue them after three days of disaster.\nThis magic, released into the world by Meoldrorth, forms hot spots from which emerge the first Elemental beings.",
    },
  },
  {
    title: "Second",
    img: images.fairy,
    years: {
      "YEAR 0":
        "The world recovers from Meoldrorth’s scorn.\nThe Dragons, now far fewer than before, slowly attempt to restore their people.\nThe newly formed Elementals wander the world, unorganized, and landless. It is still considered great luck in many cultures to happen across one.",
      "YEAR 553":
        "The Dragons struggle to rebuild their meager numbers and Meoldrorth broods over past events. They spend years on tests, studying the extent of their power.\nOne night, Meoldrorth begins one last experiment. They surreptitiously create a portal, joining their world with our Earth, which they promise as a gift to the Dragons, a new beginning. Through the portal they discover the new creatures to be small and weak, and so Meoldrorth in one wave of their hand, orders the extinction of humankind.",
      "YEAR 554 / YEAR 954 CE":
        "In the Viking era of Earth, Dragons emerge from a mystical portal in the mountains of Scandinavia and attack. Humans are overwhelmed by the magic and ferocity of such otherworldly creatures.",
      "YEAR 556 / YEAR 956 CE":
        "Vargad, for the first time in ages, emerges from the shadows to inform Melkree of Meoldrorth’s actions.\nTensions are rekindled between the families as the news breaks, each sending monster scouts through the portal, some to carve out a piece of land for themselves and others to protect the vulnerable humans from total annihilation. Clans form within the different families, all clamoring for a different cause.",
      "YEAR 559 / YEAR 959 CE":
        "Humans cross over into the realm of the monsters and lose themselves; the magic of the strange world slowly corrupts their bodies and minds. When subjected to this treatment for too long they cease to be human. This is how Faeries were introduced into their world, beings comprised of both human and magical parts, insect-like and mischievous.",
      "YEAR 566 / YEAR 966 CE":
        "Having seen enough of monsters slaughtering humans and fighting a heedless battle over Earth, Vargad sends the Demon of Light Tar’ilath to restore balance. The Demon brings knowledge of Runes to the humans. Patiently, Tar’ilath teaches them how to manipulate magic in a way that does not corrupt them.\nThe humans accept this kindness readily, finally able to defend themselves against the onslaught of magic and destruction. They enter a period of near autonomy, needing less and less help from the few creatures that used to protect them.",
    },
  },
  {
    title: "Third",
    img: images.troll,
    years: {
      "YEAR 0 / YEAR 967 CE":
        "The war for the conquest of Earth rages on, the stakes higher than ever with the help of newly acquired runes. The humans are able to hold their ground for the first time in thirteen years.",
      "YEAR 15 / YEAR 982 CE":
        "Losses amass among both sides of the seemingly endless war.\nThe humans, after much trial and error, create a gauntlet capable of unleashing the full potential of the runes to suppress the monsters’ magic, bending them to the will of whoever wields the gauntlet. The balance of power begins to shift, the humans continue to gain ground.",
      "YEAR 20 / YEAR 987 CE":
        "Humans, having perfected their magic, discover a previously untouchable advantage with their gauntlets. They can finally fight full force against the monster clans. The Earth is overwhelmed with conflict and destruction, all sides incite unimaginable chaos.",
      "YEAR 40 / YEAR 1007 CE":
        "The Earth is ransacked. The Gods, spurred to action by Vargad, finally intervene. They join forces to end the mayhem on Earth.",
      "YEAR 1054 / YEAR 2021 CE":
        "The millennium that follows is known simply as the “Status Quo.” No clan or known species dares disturb the precarious peace instilled by the Gods and all invaders leave the human world. Only the curious or particularly meddlesome creatures still risk traveling through the gates and between worlds.\nHumans live sheltered lives, very few still handle the gauntlets past down generation after generation. Over the thousand years that pass, stories of monsters and war fade into folklore and myth. Those who still carry the legacy of the gauntlet dedicate their lives to capturing any monster that lingers for too long on the wrong side of the gate.",
      "YEAR 1055 / YEAR 2022 CE":
        "A new gate appears, this time greater than any other before it.",
    },
  },
];

export const ARTICLES = {
  "0": {
    img: images.a1,
    title: "GOE'S Place In Crypto Gaming",
    desc: [
      "In an earlier blog post, we covered the main difference between play-and-earn and play-to-earn when it comes to crypto gaming. In this blog post, we will explore this idea further and expand on the benefits that you can achieve when you play Gates of Ethernity (GoE) in a win-and-earn style versus a play-for-fun game mode (our variation of free to play).",
    ],
    childs: [
      {
        sub_title:
          "What is win-and-earn? Why is it different from other common models?",
        sub_desc: [
          "As we covered previously, in the win-and-earn gaming model, the game mechanics are designed to allow the player to focus on gameplay rather than envious bidding and repetitive grinding. This is a common trait in other gaming formats such as play-to-earn, too.",
          "In play-and-earn and win-and-earn, the opportunity exists for a player to engage in a game, spend currency within the game and earn benefits or rewards such as assets or currency that have the potential to increase in value.",
        ],
      },
      {
        sub_title: "WHAT IS FREE-TO-PLAY?",
        sub_desc: [
          "Free-to-Play (F2P) games allow players to access a significant portion of a game’s content without having to pay any currency to play, or to continue to play. F2P is distinct from traditional commercial gaming software where a payment of some kind is required before using the game or service.",
          "F2P opens up the possibilities in-game where a player can take part in gameplay without having to make any bids or monetary losses. The opportunity exists to purely enjoy the game.",
          "A common F2P business model is based on the freemium software model, where users are granted significant access to a fully-functional game but are incentivized to make payments in microtransactions to access additional content or progress at a faster rate.",
          "In Aether Games, we are striving to implement a mixed variation of this model — play for fun, which will not interfere with any financial processes: within this mode, you will not lose or acquire currency.",
        ],
      },
      {
        sub_title: "WIN-AND-EARN vs PLAY-FOR-FUN IN GoE",
        sub_desc: [
          "Gates of Ethernity (GoE) and Cards of Ethernity (CoE) offer two economic model: both win-and-earn and play-for-fun Here, the emphasis is switched more towards playing the game, with the earning aspect being a pure by-product of engaging with the game.",
          "Aether Games wants our players to experience pure joy in-game and have the opportunity  to immerse themselves within the GoE lore and story.",
          "As a result of this goal, GoE differs from MANY other standard crypto games in that it does offer the opportunity for free to play, play-for-fun gameplay. The focus on making our games both fun and appealing is an alternative to the greedy, driven focus on the finance and draining of your crypto wallets that is the ultimate goal of so many other crypto games. Their single-minded monetary focus makes many of those games substantially repetitive, with a lot of grinding tasks that drive most players to sheer boredom. It also reduces the desire for replayability and re-experiencing the game all over again from the start.",
          "We also aim to avoid having any pay-to-win schemes in our games that are found in the gaming design of many other games in the crypto space.",
          "In both GoE and Cards of Ethernity (CoE), the earning capacity is still there for those that want to advance at a faster rate. Competitive players can make bids if desired and take part in paid gameplay modes which are available on demand. This ecosystem is designed with the goal of making currency earnable by engaging in gameplay. And having a lot of FUN while doing it!",
          "  At the end of the day, it is up to the game player on whether they wish to simply play the game as F2P and enjoy the gameplay without any costs or spend currency in-game to receive a return and extra enjoyment. Aether Games aims to provide both options as fun and viable alternatives for our players to decide and choose for themselves.",
        ],
      },
    ],
  },
  "1": {
    img: images.a2,
    title: "Genesis NFTs: Long-Term Value and < Secondary Market Role",
    desc: [
      "We explained previously in our blog post about the benefits of holding GoE and CoE Genesis NFTs that Genesis NFTs represent playable creatures in Gates of Ethernity. But also, possession of Genesis NFTs grants a certain exclusive status with further perks and benefits to it’s owner — hence the Genesis status.'Genesis NFTs are only available in our initial NFT drops. The first NFT drop was completed a month ago, and our second NFT drop is about to commence in one month away.There will be 1000 Genesis NFTs available in our second drop and it will be your last chance to mint one directly from us.The benefits of holding a Genesis NFT gives the holder exclusive benefits such as: a token pre-sale allocation the exclusive right to an asset or reward in all future games released by Ethernity Games Our $GoE Token is due for release in June. Some of our NFTs, when minted initially, happen to be “starter-grade”.",
    ],
    childs: [
      {
        sub_title: "What are Starter grade NFTs?",
        sub_desc: [
          "Starter-grade NFTs are our small creatures such as the Chaos Stalker, Stonewing, Tidal Wyrm, Viridis Watcher. These are small and young creatures in the first stage of their evolutionary development with unique skills and the potential for synergy. They are able to be leveled up and strengthen their abilities and powers.",
          "As any other Genesis NFTs, these starter-grade NFTs also give the holder a chance to enter a raffle contest for a free high-grade, rare NFT such as Gromgold.",
          "The key takeaway from this is that all NFTs released after our initial 3,000 will no longer be Genesis NFTs.",
          "The only ways of obtaining one of these rare Genesis NFTs are:",
          "the NFT presale (completed)\nour first drop (completed)\nour second public drop (commencing March 25th)\nthe secondary market",
          "As a result of this, our drops are one of the only ways to get a hold of these limited and rare NFTs. The largest amount of active and passive benefits usually always go to the earliest holders.",
        ],
      },
      {
        sub_title: "Genesis NFTs and Secondary Market Benefits",
        sub_desc: [
          "Aside to official public drops (only one public drop left ahead!), the only other way of obtaining a Genesis NFT quickly is the secondary market. Regarding the secondary market, the price of NFTs on marketplaces such as Open Sea can be highly volatile and, more often than not, higher than the initial mint price.These marked up prices factor in the rarity value of Genesis NFTs and the lifelong perks and benefits that they offer.",
        ],
      },
    ],
  },
  "2": {
    img: images.a3,
    title: "A Closer Look at Collectible Card Gaming Culture",
    desc: [
      "Over the last decade, the rise in popularity of many different trading and collectible card games, both physical and digital, has seen the genre explode within the global gaming community. In this post, we’ll take a closer look at the popular elements of this card gaming culture and its evolution of development.",
      "Collectible Card Games (CCGs), also known as Trading Card Games (TCGs), are a type of card game that mixes strategic deck building elements with the features of trading cards and was traditionally played in a physical, tabletop format but more recently has gained widespread use in a digital, online and often mobile format.",
      "CCGs are typically themed around fantasy or sci-fi genres. A player usually begins playing a CCG with a basic, pre-made starter deck. They are then able to earn, collect or buy more valuable and rare cards via booster packs and trading to augment their card library and build decks with stronger cards to battle with.",
      "The basic gameplay involves two or more players taking turns in drawing and playing cards and attacking opponents. Players often use some form of a mana pool that is used to summon cards onto the battlefield. Often, there are card groups that stack with one another accordingly, giving them greater power and synergy. The final winner is determined as being the player left standing after an opponent’s health points have been reduced to zero.",
    ],
    childs: [
      {
        sub_title: "POPULAR EARLY CCGs",
        sub_desc: [
          "The grand daddy of CCGs that may have inspired a large part of the entire CCG industry is Magic: The Gathering (also known as “Magic” or MTG). The game spawned from Dungeons & Dragons and was first developed and created in 1993.",
          "Another early favorite that is still around is the Pokémon Trading Card Game (often referred to as PTCG or Pokémon TCG). This game eventually spawned Pokémon Go, a 2016 augmented reality mobile game where players could use their mobile devices with GPS to locate, capture, train and battle virtual creatures. Another successful CCG is Yu-Gi-Oh!.",
        ],
      },
      {
        sub_title: "DIGITAL & VIRTUAL CCGs",
        sub_desc: [
          "While many of these earlier CCGs were played IRL with physical actual cards, the advent of the digital and internet age has seen an explosion in virtual/digital CCGs, or DCCGs – many of which can be played on handheld mobile devices or gaming consoles. One of the most popular of these digital CCGs would be Hearthstone by Blizzard Entertainment, and is based around characters and creatures developed within its World of Warcraft brand. Another popular example in this category would be Gwent: The Witcher Card Game, based around Andrzej Sapkowski’s The Witcher novels. A further example of a successful DCCG would be the Digimon Collectible Card Game.",
          "The rise in popularity of DCCGs has seen a boom in esports for many of these types of games.",
        ],
      },
      {
        sub_title: "NFT-BASED CCGs",
        sub_desc: [
          "With the growth of blockchain, cryptocurrency and NFTs, the CCG universe has expanded further to include NFT-based CCGs. Blockchain technology is powering the future of gaming and allows players to trade, sell and lease their NFT card assets.",
          "Many of these CCGs offer cards that are compatible with third-party marketplace platforms such as Open Sea, PeakMonsters and Monster Market and others, where they can be exchanged at market value.",
        ],
      },
    ],
  },
  "3": {
    img: images.a4,
    title: "ART COMPETITION Win Your NFT!",
    desc: [
      "Calling all with a passion for fan art, wanting to get into art or simply want to show off some skills! Head over to 🎨✍・art-competition with your entries!",
      "From NOW until the 28th of April at 23:59 UTC you can enter for a chance to get an NFT!",
      "This competition will count towards earning one of the Drop 2 whitelist tiers which grants a whitelist role (if you participate) – info on this can be found in 📃・nft-whitelist & 📣・announcements – after participation in this competition you will earn an art comp participant role to go towards earning tier 1 or tier 2 (or tier 1 and 2 alt)",
    ],
    childs: [
      {
        sub_title: "PRIZES AVAILABLE",
        sub_desc: [
          "GoE: 1 NFT to the champion (end of competition)\n:Your artwork shared on our official twitter @GatesOfETH (Every 2 weeks & will continue after the NFT portion deadline)\nEvery twitter winner will also earn an automatic Whitelisted role",
        ],
      },
      {
        sub_title: "REQUIREMENTS TO ENTER",
        sub_desc: [
          "1.Must be a piece of art created by yourself – drawn sketch, painting, digital art etc.\n2.Creative freedom to make something with our theme in mind is great – fan art of our own creatures would be ideal, but you are free to design your own creatures too!\n3.Your submission must be sent into 🎨✍・art-competition in order to qualify for the NFT prize\n4.Please be respectfull! This means that the piece should be appropriate (no hateful language, no racism, no political influence, no links to other projects etc.)\n5.To be entered into the twitter portion of the competition where winners are selected every 2 weeks you must post your content to twitter as well as discord and tag us @GatesOfETH",
        ],
      },
      {
        sub_title: "HOW TO WIN",
        sub_desc: [
          "Every 2 weeks the piece with the most discord reactions over that 2 week period will be shared on our twitter (granted that the piece meets the requirements as seen above)",
          " The top 3 all time reacted pieces across the competition from now until the deadline will be then put up for a final poll in which the community will decide on their favourite to win the NFT",
          " Make as much art as you can! The more entries you have the better your chances to pick up reactions towards earning either of the prizes (each piece however is individual – reactions do not stack from every submission)",
        ],
      },
    ],
  },
  "4": {
    img: images.a5,
    title: "The Lowdown on Initial Token Offerings (ITOs)",
    desc: [
      "It was only just a few years ago that Initial Coin Offerings, also known as ICOs, were all the rage in the crypto community. These days, we are probably seeing less and less of these compared to their previous numbers but something else has taken their place – Initial Token Offerings.",
    ],
    childs: [
      {
        sub_title: "INITIAL TOKEN OFFERINGS",
        sub_desc: [
          "Initial Token Offerings, or ITOs, are very similar to ICOs. The two terms are often used synonymously but there are several subtle differences between the two.",
          "While ICOs are primarily used to fundraise for a new cryptocurrency, the focus of ITOs tend to center around the offering of tokens that have a proven (or unproven) intrinsic utility.",
          "These benefits could include the token granting investors access to a platform via a subscription. Token holders could unlock the right to use exclusive services within the platform ecosystem. One example of this would be an exchange that offers lower trading fees when using their platform’s token.",
          "There is a key distinction here highlighting the difference between coins and tokens in the Crypto space (even though both terms appear to be often used simultaneously).",
          "Coins are cryptocurrencies that operate on their own independent blockchain. Examples of this are many and include Bitcoin (BTC), Ethereum (ETH) and Cardano (ADA).",
          "Tokens, on the other hand, are cryptocurrencies that operate on a sublayer within an existing blockchain. For example, token assets that typically ten to use Ethereum’s ERC-20 standard are Ethereum tokens on the ETH blockchain.",
          "Consequently, ITOs are more closely related to tokenization than to crowdfunding.",
          "ITOs can be based on fungible tokens as outlined above, or NFT based. As an NFT is a form of token (it’s an abbreviation of Non-Fungible Token), they can be used as a source of ITOs.",
          "In the promotion of our Gates of Ethernity project, we are initially offering NFTs via several drops. This will be followed shortly after with a token generation event (TGE) further down the road for our $GoE token. Our $GoE will be distributed further via a public sale due mid-2022. LBP, or Liquidity Balancing Pools, are also to be used and announced soon.",
        ],
      },
    ],
  },
  "5": {
    img: images.a6,
    title: "The Key Differences Between GoE NFTs and $GOE Tokens",
    desc: [
      "Many gaming projects use a combination of NFTs and tokens to facilitate their gaming ecosystem. We thought we should offer a brief outline as to what our Gates of Ethernity (GoE) and Cards of Ethernity (CoE) NFTs are and the utility of our $GoE token.",
    ],
    childs: [
      {
        sub_title: "GoE NFTs",
        sub_desc: [
          "In a nutshell, our GoE NFTs are ERC-721 standard tokens that can be stored in a digital currency wallet.",
          "Our NFTs will be multichain, deployed at the same time on Ethereum, Binance Smart Chain, Avalanche and Polygon.",
          "In the GoE universe, our gritty creatures, otherwise known as Ethernals, are our unique, high-quality NFT assets. Players immersed in GoE environments can go out into the real world using built-in AR technology, where they will be able to collect, battle and level-up mythical NFT creatures or monsters.",
          "This will form the foundation of our unique and immersive adventure that will be a true AAA augmented reality (AR) mobile game.",
          "These Ethernals are starter creatures that have a unique trait (passive or ability) that has the ability to evolve as they reach higher levels. If you were able to claim and mint an NFT in our first or second drop, the GoE creatures in these bundles are genesis NFTs that have a future use case by holding them.",
          "These genesis NFTs should be perceived as being more valuable than getting a similar version in-game after minting. The reason for this is that all holders of genesis NFTs will also automatically be given an asset or NFT for any future games that Ethernity Games will release in the future.",
          "The rarity and stats of each of our GoE NFT creatures can be viewed in our previous blog post “A Breakdown of Contents for our First NFT Drop”.",
          "If you missed out on getting a genesis NFT in our first drop, there’s still a chance to claim one in our second drop scheduled for late March 2022 where a further 1000 mystery boxes will be available",
          "In our Cards of Ethernity (CoE) game, all of our collectible cards (150 in total) will be NFTs that can be traded and used in gameplay.",
        ],
      },
      {
        sub_title: "$GoE TOKEN",
        sub_desc: [
          "Our $GoE token will form the heart of our entire gaming ecosystem. $GoE token is intended to be used as a payment and reward system within all games in the Ethernity Games franchise.",
          "In essence, $GoE token will be our in-house cryptocurrency and it will be used to cover the transaction fees in our own layer2 (L2) subnet. The Ethereum L2 chain has multiple sidechains  – Binance Smart Chain (BSC) and Polygon (MATIC) with the help of tools provided by the Avalanche (AVAX) subnet.",
          "There will also be staking and liquidity rewards for holding $GoE. In addition, the token will be available to the general public and specifically to community members, and eventually will be able to be traded on various crypto exchanges.",
          "In Gates of Ethernity, you will be able to use $GoE token for:",
          "In-game consumables\nAdventurers\nNFTs / Ethernals\nPay for any transaction fees.",
          "In Cards of Ethernity, you will be able to use $GoE token for:",
          "Card packs\nEthernals, spells and relics\nTreasure chests & keys for treasure chests\nA Battle Pass\nGolden Ticket – which allows for entry into the Colosseum\nCard backs\nAdventurers’ skins\nGoodwill\nThematic card decks\nPay for any transaction fees.",
          "It is important to point out that whilst there are differences between our NFTs and our tokens, in our situation, both go hand-in-hand to form an overall ecosystem.",
        ],
      },
    ],
  },
};
