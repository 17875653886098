import { FC } from "react";
import { icons } from "../../assets";


export const Snackbar:FC<any> = (props:any) => {

    const color = (typ:string) => {
        var _col = "green"
        if(typ.toLowerCase().includes("warn")){
            _col = "orange";
        }else if(typ.toLowerCase().includes("error")){
            _col = "red"
        }
            return _col
        };

    return (
        <div className={`notify`} id="notify">
            <h3 id="noti-text" style={{color:color(props.msg)}}>{props.msg}</h3>
            <img src={icons.close} alt="close icon"/>
        </div>
    );
}