import { useEffect, useState } from "react";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { CREATURES } from "../../utils/data/constants";
import { toggleVideo } from "../../utils/helpers";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


export const CarouselSlider = () => {

  const [slidesNum, setSlidesNum] = useState<number>(1);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener('resize', handleResize);
  },[])


  const handleResize = () => {
    var _size = window.innerWidth;

    if(_size < 768){
      setSlidesNum(1)
    }else if(_size > 768 && _size < 1200){
      setSlidesNum(3)
    }else if(_size > 1200 && _size <= 1920){
      setSlidesNum(5);
    }else{
      setSlidesNum(7)
    }
  }


  return (
    <Swiper
      slidesPerView={slidesNum}
      spaceBetween={30}
      slidesPerGroup={1}
      loop={true}
      loopFillGroupWithBlank={true}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Pagination, Navigation]}
      className="mySwiper"
    >
      <ul>
        {CREATURES.map((c, idx) => (
          <SwiperSlide>
            {" "}
            <li
              className="creature-item"
              key={idx}
              style={{
                backgroundImage: `url(${c.bg})`,
                backgroundSize: "100% 100%",
              }}
              onClick={() => toggleVideo(c.name)}
            >
              <section>
                <h3>{c.name}</h3>
                <p>{c.category}</p>
                <p>
                  <span>{c.evo}</span>
                </p>
              </section>
              <video
                src={c.vid}
                autoPlay={true}
                muted={true}
                id={c.name}
                loop
              ></video>
            </li>
          </SwiperSlide>
        ))}
      </ul>
    </Swiper>
  );
};
