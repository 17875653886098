import { images } from "../../assets";

export const AgeCard = () => {

    const AGEDESCRIPTION = [
        {
            title:"YEAR 0 / YEAR 967 CE",
            desc:"The world recovers from Meoldrorth’s scorn.\nThe Dragons, now far fewer than before, slowly attempt to restore their people. The newly formed Elementals wander the world, unorganized, and landless. It is still considered great luck in many cultures to happen across one.",
        },
        {
            title:"YEAR 20 / YEAR 987 CE",
            desc:"",
        },
        {
            title:"YEAR 1054 / YEAR 2021 CE",
            desc:"",
        },
        {
            title:"YEAR 1055 / YEAR 2022 CE",
            desc:"",
        },
        {
            title:"YEAR 40 / YEAR 1007 CE",
            desc:"",
        },
        {
            title:"YEAR 15 / YEAR 982 CE",
            desc:"",
        },

    ]

  return (
    <div className="age_c flex center">
      <div className="age_card">
        <div className="age_img">
            <div style={{backgroundImage:`url(${images.third_h})`, backgroundSize:"cover"}} className="flex center">
                <span>
                  <h4>THE THIRD AGE</h4>
                </span>
            </div>
        </div>

        <div className="age_det">
          <ul>
            {AGEDESCRIPTION.map((a,idx) => 
            <li key={idx}>
              <h4>{a.title}</h4>
              <span>
                {a.desc}
              </span>
            </li>
         )}
         </ul>
        </div>
      </div>
    </div>
  );
};
