import { FC, useEffect } from "react";
import { images } from "../../assets";
import { FOOTER, SOCIALS } from "../../utils/data/constants";



export const Footer:FC = () => {
        let path_name = window.location.pathname

    return(
        <footer className="ftr">
            <ol>{FOOTER.map((i,idx) => 
                <li key={idx}>
                    <a href={i.link} className={path_name === i.link ? "nav_route" : ""}>
                        {i.title}
                    </a>
                </li>)}
            </ol>
            <ul>{SOCIALS.map((i,idx) => <li key={idx}><a href={i.link} target={"_blank"}><img src={i.icon}/></a></li>)}</ul>
            <img src={images.ftr_logo}/>
            <h4>&copy;Aether games Inc. 2022. {'\n'}All rights reserved</h4>
        </footer>
    )
}