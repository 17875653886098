import { injectedConnector, walletconnect } from "../data/connectors";



export function connectMapper(conn_type: string, active: any, activate: any) {
  if (!active) {
    switch (conn_type) {
      case "Metamask":
        return connectInjected(activate);
      case "WalletConnect":
        return connectWalletConnect(activate);
      default:
        return connectInjected(activate);
    }
  }
};
async function connectInjected(activate: any) {
  console.log("Start connecting");
  try {
    await activate(injectedConnector);
  } catch (ex) {
    console.log(ex);
  }
};
async function connectWalletConnect(activate: any) {
  console.log("Connecting to WalletConnect...");
  await activate(walletconnect);
};
export async function disconnect(deactivate: any) {
  try {
    await deactivate();
    console.log("Disconnected!");
  } catch (ex) {
    console.log(ex);
  }
};
