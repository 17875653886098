import { emailRegex, truncateRegex } from "../data/constants";

export function TrimAddress(address: string) {
  const match = address.match(truncateRegex);
  if (!match) {
    return address;
  }
  return `${match[1]}…${match[2]}`;
};

export function CountDown(target: number) {
  const targetTime = target; // in ms
  const cuur_time = new Date().getTime();
  const left_time = targetTime - cuur_time;

  var d = Math.floor(left_time / (1000 * 60 * 60 * 24)).toString();
  var h = Math.floor(left_time / (1000 * 60 * 60)).toString();
  var m = Math.floor((left_time % (1000 * 60 * 60)) / (1000 * 60)).toString();
  var s = Math.floor((left_time % (1000 * 60)) / 1000).toString();
  return {
    s,
    m,
    h,
    d,
  };
};

export function animateProgress(bar_name: string, val: number, currentVal: number) {
  var bar = document.getElementById(bar_name) as any;

  currentVal = currentVal || 0;

  var step = (val * 16) / 500;

  function animate(currentVal: number) {
    currentVal += step;

    bar.value = currentVal;

    currentVal < val &&
      requestAnimationFrame(function () {
        animate(currentVal);
      });
  }

  animate(currentVal);
};

export function displayNotify() {
  var notify = document.getElementById("notify");
    notify!.className += " active"
  setTimeout(() => {
    notify!.className = "notify"
  }, 2500);
};

export function copyAddress(adds:string) {
  navigator.clipboard.writeText(adds);
  displayNotify();
};

export async function ParseData(url: string) {
  var data = {};
  data = await fetch(url).then((res) => res.json());
  return data;
};

export function countWords(string:string) {
  const arr = string.split(' ');
  return arr.filter(word => word !== '').length;
}

export const lineStyle = (idx:number, age:number) =>  {
  var _line = document.getElementById(`line-progress${age}`)

  switch(idx){
      case 0:
          return _line!.style.height = '10%';
      case 1:
          return _line!.style.height = '20%';
      case 2:
          return _line!.style.height = '40%';
      case 3:
          return _line!.style.height = '60%';
      case 4:
          return _line!.style.height = '80%';
      case 5:
          return _line!.style.height = '100%';
      default:
          return _line!.style.height = '10%';
  };
};

export const isEmail = (email:string):boolean => {
  if(emailRegex.test(email)){
    return true
  }
  return false;
};

export function toggleVideo(name: string) {
  var vid = document.getElementById(name) as any;
  vid.play();
};