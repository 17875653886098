import { useState } from "react"
import { icons } from "../../assets"
import { ARTICLES, SOCIALS } from "../../utils/data/constants"

export const Blog = () => {

    const [shownArts, setShownArts] = useState(3);


    return(
        <div className="blog">
            <div className="blog-bg flex col center">
                <section className="flex col center">
                    <h1>Gates of Ethernity Blog</h1>
                    <h3>Blogposts | Notes | Development Updates</h3>
                    <div className="flex wrap center">
                        <input type={"text"} placeholder="Enter your email" />
                        <button>Subscribe</button>
                    </div>
                </section>
            </div>

            <div className="arts-card">
                <div className="arts-cont"/>
                <section>
                    <div className="bl_ls">
                        <div className="blog-search flex wrap">
                            <input type={"text"} placeholder="Enter your email" />
                            <button>Search</button>
                        </div>
                        <ul>
                            {Object.values(ARTICLES).slice(shownArts).map((b, idx) => 
                            <li key={idx} onClick={() => window.open('/blog/article'+`?article=`+idx)}>
                                <img src={b.img}/>
                                <section className="flex col">
                                    <div>
                                        <h6><img src={icons.cal}/> 2022 04 14&nbsp;&nbsp;&nbsp;&nbsp;|<img src={icons.pers}/> 4 min read</h6>
                                    </div>
                                    <h2>{b.title}</h2>
                                    <p>{b.desc[0].split(" ").splice(0,30).join(" ")}</p>
                                </section>
                            </li>
                            )}
                        </ul>
                    </div>

                    <div className="bl-rs">
                        <div className="rs-soc">
                            <h3>Follow Us</h3>
                            <ul>
                                {SOCIALS.map((s, idx) => 
                                <li key={idx}>
                                    <a href={s.link}>
                                        <img src={s.icon}/>
                                        <span>{s.title}</span>
                                    </a>
                                </li>
                                )}
                            </ul>
                        </div>
                        
                        <div className="rs-ba">
                            <h3>Best Articles</h3>
                            <ul>
                                {Object.values(ARTICLES).map((b,idx) => 
                                <li key={idx}>
                                    <a href={`/blog/article?article=${idx}`}>
                                    <div>
                                        <h6><img src={icons.cal}/> 2022 04 14&nbsp;&nbsp;&nbsp;&nbsp;|<img src={icons.pers}/> 4 min read</h6>
                                    </div>
                                    <section>
                                        <img src={b.img}/>
                                        <p>{b.title}</p>
                                    </section>
                                    </a>
                                </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    
                </section>

                <div className="more">
                    
                    <button onClick={() => setShownArts((e) => e===3 ? 0 : 3)}>Show {shownArts === 3 ? "More" : "Less"} <img src={icons.right_arr}/></button>
                </div>

            </div>
        </div>
    )
}