import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { injectedConnector } from "../utils/data/connectors";
import { images } from "../assets";

interface Props {
  children: React.ReactElement;
}
declare let window: any;

export const MetamaskProvider = ({ children }: Props): React.ReactElement => {
  const { active, error, activate } = useWeb3React<Web3Provider>();
  const [logo, setLogo] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
    // for automatic connection to metamask

    if (!loaded) {
      injectedConnector
        .isAuthorized()
        .then((isAuthorized) => {
          setLoaded(true);

          if (isAuthorized && !active && !error) {
            activate(injectedConnector);
          }
        })
        .catch(() => {
          setLoaded(true);
        });
    }

    if (window.ethereum && window.ethereum.on && !active && !error) {
      const handleChainChanged = () => {
        activate(injectedConnector);
      };

      const handleAccountsChanged = (accounts: any) => {
        if (accounts.length > 0) {
          activate(injectedConnector);
        }
      };

      const handleNetworkChanged = () => {
        activate(injectedConnector);
      };

      window.ethereum.on("chainChanged", handleChainChanged);
      window.ethereum.on("accountsChanged", handleAccountsChanged);
      window.ethereum.on("networkChanged", handleNetworkChanged);

      return () => {
        if (window.ethereum.removeListener) {
          window.ethereum.removeListener("chainChanged", handleChainChanged);
          window.ethereum.removeListener(
            "accountsChanged",
            handleAccountsChanged
          );
          window.ethereum.removeListener(
            "networkChanged",
            handleNetworkChanged
          );
        }
      };
    }
   }, [active, error, activate]);

  setTimeout(() => {
    setLogo(true)
  },1000)

  if (loaded && logo) {
    return children;
  }

  return <div id="logo" className="flex center"><img src={images.goe}/></div>;
};
