import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AgeCard } from "../components/AgeCard";
import { Providers } from "../contexts";
import { Blog } from "./Blog";
import { Article } from "./Blog/article";
import { Landing } from "./Landing";
import { Story } from "./Story";
import { Test } from "./Test";

export function Views() {

  return (
    <Providers>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/article" element={<Article />} />
          <Route path="/story" element={<Story />} />

          <Route path="/ageCard" element={<AgeCard />} />
          <Route path="/test" element={<Test />} />

          <Route path="/*" element={<Landing />} />
        </Routes>
      </Router>
    </Providers>
  );
}

export default Views;
