import React from 'react';
import { Footer } from './Footer';
import { Navbar } from './Navbar';


export const AppLayout = React.memo(function AppLayoutImpl(props: any) {
    return (
      <>
          <Navbar/>
          {props.children}
          <Footer/>
        </>
    );
  });
  