import { Views } from "./views";
import './styles/index.css';
import './index.css';


function App() {
  return <Views />;
}

export default App;
